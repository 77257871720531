import { useLocation, useNavigate } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { array, date, number, object, string } from "yup";
import i18n from "../../library/i18next";
import { NSInterviewDetailType } from "./Interview-Detail.type";
import { useGraphQLMutation, useGraphQLQuery } from "../../hooks/useGraphQL";
import {
  GetProjectEditInfoDocument,
  GetReviewMetricByRoleAndLanguageDocument,
  GetReviewMetricQuestionsByMetricIdDocument,
  Language_Enum,
  UpdateProjectLanguageDocument,
  UpdateProjectStartAndEndDateDocument,
} from "../../gql/graphql";
import toast from "react-hot-toast";
import ToastAlert from "../../components/ToastAlert/ToastAlert";
import gqGetInterviewTemplateQuestionsByTemplateId from "../../graphql/getInterviewTemplateQuestionsByTemplateId.graphql";
import {
  InterviewDetailPageType,
  useInterviewDetailContext,
} from "../../context/InterviewContext/Interview-Detail/InterviewDetailContext";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const languageSchema: NSInterviewDetailType.ILanguageSelectionFormValues | any =
  object({
    language: array(object({ id: number(), label: string() }))
      .required(i18n.t("forms.selectLanguageError"))
      .default(undefined),
  }).required();

const dateSchema: NSInterviewDetailType.IDateSelectionFormValues | any = object(
  {
    timePeriod: object({ startDate: date(), endDate: date() }).notRequired(),
  }
).required();

const useInterviewDetailVM = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const projectId = searchParams.get("projectId");
  const navigate = useNavigate();

  const {
    activePage,
    metricQuestionItems,
    activeStepId,
    stepProgressBarItems,
    metricItems,
    isValidQuestions,
    warningModalIsOpen,
    setWarningModalIsOpen,
    isNoticeModalOpen,
    isAllMetricQuestionsSelected,
    getNextMetricsQuestions,
    getPreviousMetricsQuestions,
    onClickModalClose,
    setMetricItems,
    setStepProgressBarItems,
    setMetricQuestionItems,
    setIsValidQuestions,
  } = useInterviewDetailContext();

  const [currentPreviewQuestionIndex, setCurrentPreviewQuestionIndex] =
    useState<number>(0);

  /*   const [editedQuestionId, setEditedQuestionId] = useState<number | null>(null); */

  const languageOptions = [
    {
      id: 1,
      label: Language_Enum.Tr.toUpperCase(),
    },
    {
      id: 2,
      label: Language_Enum.En.toUpperCase(),
    },
  ];

  const { handleSubmit: handleSubmitDate, control: controlDate } =
    useForm<NSInterviewDetailType.IDateSelectionFormValues>({
      resolver: yupResolver(dateSchema),
      shouldFocusError: false,
    });

  const { mutateAsync: updateLanguage } = useGraphQLMutation(
    UpdateProjectLanguageDocument
  );

  const { mutateAsync: updateDate } = useGraphQLMutation(
    UpdateProjectStartAndEndDateDocument
  );

  const selectedMetricItems = metricItems.filter(
    (item) => item?.isSelected === true
  );
  const isSelectedCompetenciesValid =
    selectedMetricItems.length >= 3 && selectedMetricItems.length <= 5;

  const onSubmitLanguage: SubmitHandler<
    NSInterviewDetailType.ILanguageSelectionFormValues
  > = (data, event) => {
    event?.preventDefault();
    updateLanguage({
      projectId: projectId,
      language: data.language[0].label?.toLowerCase() as Language_Enum,
    })
      .then(() => {
        navigate(-1);
        toast(
          <ToastAlert
            description={i18n.t("projects.updateProjectLanguageSuccess")}
            type="success"
          />,
          {
            id: "updateProjectLanguageSuccess",
          }
        );
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("projects.updateProjectLanguageError")}
            type="error"
          />,
          {
            id: "updateProjectLanguageError",
          }
        );
      });
  };

  const onSubmitDate: SubmitHandler<
    NSInterviewDetailType.IDateSelectionFormValues
  > = (data, event) => {
    event?.preventDefault();

    const adjustedStartDate = dayjs(data.timePeriod.startDate)
      .add(3, "hour")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    const adjustedEndDate = dayjs(data.timePeriod.endDate)
      .add(3, "hour")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    updateDate({
      projectId: projectId,
      interview_ends_at: adjustedEndDate,
      interview_starts_at: adjustedStartDate,
    })
      .then(() => {
        navigate(-1);
        toast(
          <ToastAlert
            description={i18n.t("projects.updateProjectDateSuccess")}
            type="success"
          />,
          {
            id: "updateProjectDateSuccess",
          }
        );
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("projects.updateProjectDateError")}
            type="error"
          />,
          {
            id: "updateProjectDateError",
          }
        );
      });
  };

  const { data: projectsData, isSuccess } = useGraphQLQuery(
    GetProjectEditInfoDocument,
    undefined,
    {
      projectId,
    }
  );

  const { data: interviewTemplateQuestions, isSuccess: isTemplateSuccess } =
    useGraphQLQuery(
      gqGetInterviewTemplateQuestionsByTemplateId,
      {
        enabled:
          projectsData &&
          (activePage === InterviewDetailPageType.SEE_IN_PREVIEW ||
            activePage ===
              InterviewDetailPageType.REVIEW_METRIC_QUESTION_SELECTION ||
            activePage === InterviewDetailPageType.REVIEW_METRIC_SELECTION),
      },
      {
        id: projectsData?.project_by_pk?.interview_template?.id as number,
      }
    );

  const {
    data: metricQuestionsData,
    refetch: refetchMetricQuestions,
    isSuccess: isSuccessMetricQuestions,
  } = useGraphQLQuery(
    GetReviewMetricQuestionsByMetricIdDocument,
    {
      enabled: false,
    },
    {
      reviewMetricId: activeStepId,
    }
  );

  const templateQuestionsLanguages = [
    ...new Set(
      interviewTemplateQuestions?.interview_template_review_metric_question.map(
        (i) => i.review_metric_question.review_metric.language
      )
    ),
  ];

  const { data: metricsData, isSuccess: isSuccessMetrics } = useGraphQLQuery(
    GetReviewMetricByRoleAndLanguageDocument,
    {
      enabled:
        !!projectsData?.project_by_pk?.role?.id &&
        activePage === InterviewDetailPageType.REVIEW_METRIC_SELECTION &&
        metricItems.length === 0,
    },
    {
      roleId: projectsData?.project_by_pk?.role?.id,
      language: templateQuestionsLanguages,
    }
  );

  const setStepBarItems = () => {
    const stepProgressBarItems = metricItems
      .filter((item) => item.isSelected === true)
      .map((item) => ({
        id: item.id,
        label: item.name,
      }));
    setStepProgressBarItems(stepProgressBarItems ?? []);
  };

  const onClickGoToQuestionSelectionStep = () => {
    navigate(
      `/projects/interview-detail/REVIEW_METRIC_QUESTION_SELECTION?projectId=${projectId}`
    );
  };

  const onClickInfoIcon = (id: number) => {
    navigate(
      `/projects/interview-detail/REVIEW_METRIC_QUESTION_SELECTION/question/${id}`
    );
  };

  useEffect(() => {
    if (
      activePage === InterviewDetailPageType.REVIEW_METRIC_QUESTION_SELECTION
    ) {
      setStepBarItems();
    }
  }, [activePage, metricItems]);

  useEffect(() => {
    switch (activePage) {
      case InterviewDetailPageType.REVIEW_METRIC_SELECTION:
        if (metricsData && !metricItems.length && interviewTemplateQuestions) {
          if (!metricsData.role_review_metric?.length) {
            toast(
              <ToastAlert
                description={i18n.t("interview.notFoundRoleMetricsError")}
                type="error"
              />,

              {
                id: "notFoundRoleMetricsError",
              }
            );
            navigate(-1);
          }

          const selectedMetricsIds =
            interviewTemplateQuestions?.interview_template_review_metric_question.map(
              (item) => item.review_metric_question.review_metric.id
            );

          const initialMetricItems = metricsData?.role_review_metric.map(
            (item) => ({
              id: item.review_metric.id,
              name: item.review_metric.description,
              isSelected: selectedMetricsIds?.includes(item.review_metric.id),
            })
          );

          setMetricItems(initialMetricItems);
        } else if (!metricsData) {
          toast(
            <ToastAlert
              description={i18n.t("interview.notFoundRoleMetricsError")}
              type="error"
            />,

            {
              id: "notFoundRoleMetricsError",
            }
          );
          navigate(-1);
        }
        break;
      case InterviewDetailPageType.REVIEW_METRIC_QUESTION_SELECTION:
        if (interviewTemplateQuestions) {
          stepProgressBarItems?.length > 0 &&
            !metricQuestionItems?.[activeStepId]?.length &&
            refetchMetricQuestions();

          const selectedQuestionsIds =
            interviewTemplateQuestions?.interview_template_review_metric_question
              .filter(
                (item) =>
                  item.review_metric_question.review_metric.id === activeStepId
              )
              .map((item) => item.review_metric_question.id);

          const initialMetricQuestionItems =
            metricQuestionsData?.review_metric_question.map((item) => ({
              id: item.id,
              name: item.description,
              isSelected: selectedQuestionsIds?.includes(item.id),
              language:
                item.review_metric.language.toUpperCase() as Language_Enum,
              maxDuration: 5,
              isShownToCandidate: item.is_shown_to_candidate,
            }));

          if (
            initialMetricQuestionItems &&
            !initialMetricQuestionItems.length
          ) {
            toast(
              <ToastAlert
                description={i18n.t("interview.notFoundMetricQuestionsError")}
                type="error"
              />,

              {
                id: "notFoundMetricQuestionsError",
              }
            );
          }

          if (activeStepId) {
            setMetricQuestionItems((prevItems: any) => ({
              ...prevItems,
              [activeStepId]: initialMetricQuestionItems ?? [],
            }));
          }
        }
      default:
        break;
    }
  }, [
    activeStepId,
    metricQuestionsData,
    metricsData,
    interviewTemplateQuestions,
  ]);

  const projectName = projectsData?.project_by_pk?.project_name;
  const initialLanguages = languageOptions.filter(
    (option) =>
      option.label.toLowerCase() ===
      projectsData?.project_by_pk?.language.toLowerCase()
  );

  const { handleSubmit: handleSubmitLanguage, control: controlLanguage } =
    useForm<NSInterviewDetailType.ILanguageSelectionFormValues>({
      resolver: yupResolver(languageSchema),
      shouldFocusError: false,
      defaultValues: {
        language: initialLanguages,
      },
    });

  const submitHandlerLanguage = () => handleSubmitLanguage(onSubmitLanguage);
  const submitHandlerDate = () => handleSubmitDate(onSubmitDate);

  const previewQuestions =
    (interviewTemplateQuestions &&
      interviewTemplateQuestions?.interview_template_review_metric_question.filter(
        (question) =>
          question.review_metric_question.is_shown_to_candidate === true
      )) ||
    [];

  const notLastPreviewQuestion =
    currentPreviewQuestionIndex < previewQuestions.length - 1;

  const onClickNextPreviewQuestion = () => {
    if (notLastPreviewQuestion) {
      setCurrentPreviewQuestionIndex(currentPreviewQuestionIndex + 1);
    } else {
      navigate(-1);
    }
  };

  const updateMetricQuestionItems = (questions: any) => {
    setMetricQuestionItems((prevItems: any) => ({
      ...prevItems,
      [activeStepId]: questions,
    }));
  };

  const currentPreviewQuestion =
    previewQuestions[currentPreviewQuestionIndex]?.review_metric_question;

  const defaultDates = [
    projectsData?.project_by_pk?.interview_starts_at,
    projectsData?.project_by_pk?.interview_ends_at,
  ];

  const isLoading = !isSuccess;
  const isLoadingReviewMetricQuestions = !isSuccessMetricQuestions;
  const isLoadingReviewMetrics = !isSuccessMetrics;
  const isPreviewLoading =
    !isTemplateSuccess ||
    !interviewTemplateQuestions.interview_template_review_metric_question
      .length;

  return {
    projectName,
    isPreviewLoading,
    isLoadingReviewMetricQuestions,
    isLoadingReviewMetrics,
    controlLanguage,
    controlDate,
    languageOptions,
    initialLanguages,
    defaultDates,
    isLoading,
    notLastPreviewQuestion,
    currentPreviewQuestion,
    currentPreviewQuestionIndex,
    interviewTemplateQuestions,
    metricItems,
    isSelectedCompetenciesValid,
    metricQuestionItems,
    activeStepId,
    stepProgressBarItems,
    isValidQuestions,
    warningModalIsOpen,
    setWarningModalIsOpen,
    isNoticeModalOpen,
    isAllMetricQuestionsSelected,
    previewQuestions,
    getNextMetricsQuestions,
    getPreviousMetricsQuestions,
    setMetricItems,
    setIsValidQuestions,
    onClickNextPreviewQuestion,
    handleSubmitDate: submitHandlerDate,
    onClickGoToQuestionSelectionStep,
    onClickInfoIcon,
    updateMetricQuestionItems,
    onClickModalClose,
    handleSubmitLanguage: submitHandlerLanguage,
  };
};

export default useInterviewDetailVM;
