import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import idx from "idx";
import {
  ButtonColorVariant,
  IButton,
} from "../../../components/MainButton/MainButton.type";
/* eslint-disable no-lonely-if */

import { NSMetricPreviewBarType } from "../../../components/MetricPreviewBar/MetricPreviewBar.type";
import i18n from "../../../library/i18next";
import useGetVideoInterview from "../../../service/VideoInterview/getVideoInterview";
import useGetVideoInterviewVideos from "../../../service/VideoInterview/getVideoInterviewVideos";
import useInsertVideoInterviewScore from "../../../service/VideoInterview/insertVideoInterviewScore";
import { NSCandidateReviewerQuestionType } from "./Candidate-Reviewer-Question.type";
import { useGraphQLMutation } from "../../../hooks/useGraphQL";
import {
  UpdateVideoInterviewScoreDocument,
  Video_Interview_Type_Enum,
} from "../../../gql/graphql";
import { NSCandidateListType } from "../../Project/Detail/Candidate-List/Candidate-List.type";

const useCandidateReviewerQuestionVm = () => {
  const { state } = useLocation();
  const interviewId = useMemo(() => state.interviewId, [state]);
  const { projectId } = state;

  const { data, refetch } = useGetVideoInterview(interviewId);
  const baseType =
    data?.video_interview_by_pk?.video_interview_type ===
    Video_Interview_Type_Enum.CompetencyAssessment
      ? NSCandidateListType.ScorePageBaseType.QUESTION
      : NSCandidateListType.ScorePageBaseType.INTERVIEW;

  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [activeMetric, setActiveMetric] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [isAIReview, setIsAIReview] = useState(false);
  const { mutateAsync: insertVideoInterviewScoreMutateAsync } =
    useInsertVideoInterviewScore();

  const { mutateAsync: updateVideoInterviewDesc } = useGraphQLMutation(
    UpdateVideoInterviewScoreDocument
  );

  const closeHandler = useCallback(() => {
    navigate(-1);
  }, [navigate, projectId]);

  const questions = useMemo(
    () => data?.video_interview_by_pk?.video_interview_questions,
    [data?.video_interview_by_pk]
  );

  const reviewMetricQuestions = useMemo(
    () =>
      data?.video_interview_by_pk?.client_candidate_project?.project
        .interview_template?.interview_template_review_metric_questions,
    [data?.video_interview_by_pk?.client_candidate_project]
  );

  const candidateInformation = useMemo(
    () => ({
      name: `${data?.video_interview_by_pk?.client_candidate_project.client_candidate.name} ${data?.video_interview_by_pk?.client_candidate_project.client_candidate.surname} `,
      role:
        data?.video_interview_by_pk?.client_candidate_project?.project
          ?.project_name ?? "",
    }),
    [
      data?.video_interview_by_pk?.client_candidate_project.client_candidate
        .name,
      data?.video_interview_by_pk?.client_candidate_project.client_candidate
        .surname,
      data?.video_interview_by_pk?.client_candidate_project?.project
        ?.project_name,
    ]
  );

  const barItems: NSMetricPreviewBarType.IMetricPreviewBarItem[] | undefined =
    useMemo(() => {
      const metricItem = questions?.map((questionsItem, index) => ({
        id: questionsItem.review_metric_question.review_metric.id,
        value: index,
        label: `${index + 1}. ${
          questionsItem.review_metric_question.review_metric.description
        }`,
        isHaveWeight:
          reviewMetricQuestions?.find(
            (x) =>
              x.review_metric_question.id ===
              questionsItem.review_metric_question.id
          )?.weight !== 0,
      }));
      return metricItem;
    }, [questions, reviewMetricQuestions]);

  const questionsWithMetric = useMemo(
    () =>
      barItems?.map((barItem) => ({
        metricLabel: barItem.label,
        metricId: barItem.id,

        questions: questions
          ?.filter((_filterItem, index) => index === barItem.value)
          .map((questionItem) => ({
            id: questionItem.id,
            questionLabel: questionItem.review_metric_question.question,
            questionId: questionItem.review_metric_question.id,
            description:
              questionItem.review_metric_question.reviewer_description,
            videoInterviewScore: questionItem.video_interview_score,
            isOnlyAISelected:
              questionItem.video_interview_score.length === 1 &&
              questionItem.video_interview_score[0].is_ai_score,
            isHaveWeight:
              reviewMetricQuestions?.find(
                (x) =>
                  x.review_metric_question.id ===
                  questionItem.review_metric_question.id
              )?.weight !== 0,

            responses:
              questionItem.review_metric_question.review_metric_question_responses.map(
                (responseItem) => {
                  const isSelected =
                    questionItem.video_interview_score.find(
                      (i) => i.is_ai_score === false
                    )?.video_interview_question_id === questionItem.id &&
                    questionItem.video_interview_score.find(
                      (i) => i.is_ai_score === false
                    )?.review_metric_question_response_id === responseItem.id;
                  const isAISelected =
                    questionItem.video_interview_score.find(
                      (i) => i.is_ai_score === true
                    )?.video_interview_question_id === questionItem.id &&
                    questionItem.video_interview_score.find(
                      (i) => i.is_ai_score === true
                    )?.review_metric_question_response_id === responseItem.id;
                  const hasReason =
                    (isSelected &&
                      !!questionItem.video_interview_score.find(
                        (i) => i.is_ai_score === false
                      )?.score_description) ||
                    (isAISelected &&
                      !!questionItem.video_interview_score.find(
                        (i) => i.is_ai_score === true
                      )?.score_description);
                  const userInfo = isSelected
                    ? {
                        name: questionItem.video_interview_score.find(
                          (i) => i.is_ai_score === false
                        )?.video_interview_scored_by?.name,

                        avatarUrl:
                          questionItem.video_interview_score.find(
                            (i) => i.is_ai_score === false
                          )?.video_interview_scored_by?.avatar ?? undefined,
                      }
                    : undefined;
                  const isHaveWeight =
                    reviewMetricQuestions?.find(
                      (x) =>
                        x.review_metric_question.id ===
                        questionItem.review_metric_question.id
                    )?.weight !== 0;

                  return {
                    id: responseItem.id,
                    label: responseItem.description,
                    score: responseItem.score,
                    isSelected: !!isSelected,
                    isAISelected: !!isAISelected,
                    hasReason,
                    userInfo,
                    isHaveWeight,
                  };
                }
              ),
          })),
      })),
    [barItems, questions]
  );
  const activeQuestionProps = useMemo(
    () => ({
      questionHeader: `${activeQuestion + 1} / ${
        questionsWithMetric?.[activeMetric]?.metricLabel
      } `,
      question:
        questionsWithMetric?.[activeMetric]?.questions?.[activeQuestion],
      description:
        questionsWithMetric?.[activeMetric]?.questions?.[activeQuestion]
          .description,
      id: questionsWithMetric?.[activeMetric]?.questions?.[activeQuestion]
        .questionId,
      isOnlyAISelected:
        questionsWithMetric?.[activeMetric]?.questions?.[activeQuestion]
          .isOnlyAISelected && selectedAnswer === undefined,
      isHaveWeight:
        questionsWithMetric?.[activeMetric]?.questions?.[activeQuestion]
          .isHaveWeight && selectedAnswer === undefined,
    }),

    [activeMetric, activeQuestion, questionsWithMetric, selectedAnswer]
  );

  const onClickOptionItem = useCallback(
    async (answerOptionId: number) => {
      const questionId = idx(
        questionsWithMetric,
        (_) => _[activeMetric].questions[activeQuestion].id
      );

      const selectedAnswerProp =
        answerOptionId ??
        activeQuestionProps.question?.responses.find((i) => i.isSelected)?.id;

      const isNotAISelected =
        activeQuestionProps.question?.responses.find((i) => i.isAISelected)
          ?.id !== answerOptionId;

      if (isNotAISelected && selectedAnswerProp && questionId) {
        await insertVideoInterviewScoreMutateAsync({
          videoInterviewQuestionId: questionId,
          responseId: selectedAnswerProp,
          scoreDescription: null,
        });
        setSelectedAnswer(answerOptionId);
      }
    },
    [
      activeMetric,
      activeQuestion,
      activeQuestionProps.question?.responses,
      insertVideoInterviewScoreMutateAsync,
      questionsWithMetric,
    ]
  );

  const videoFilenames = useMemo(
    () => `${data?.video_interview_by_pk?.id}-${activeQuestionProps.id}.mp4`,
    [activeQuestionProps.id, data?.video_interview_by_pk?.id]
  );

  const { data: videoUrls } = useGetVideoInterviewVideos(
    videoFilenames.length ? [videoFilenames] : []
  );

  const onClickContinue = useCallback(async () => {
    const questionsCount =
      questionsWithMetric?.[activeMetric].questions?.length ?? 0;
    const barItemsCount = (barItems?.length ?? 0) - 1;
    if (questionsCount - 1 > activeQuestion) {
      setActiveQuestion(activeQuestion + 1);
    } else {
      if (barItemsCount > activeMetric) {
        setActiveMetric(activeMetric + 1);
      } else {
        navigate(
          `/projects/detail/${projectId}/candidate/candidate-score-result/${interviewId}/${baseType}`,
          {
            state: { projectId, interviewId },
          }
        );
      }
    }
    setSelectedAnswer(undefined);
  }, [
    questionsWithMetric,
    activeMetric,
    activeQuestion,
    barItems?.length,
    navigate,
    projectId,
    interviewId,
  ]);

  const onClickMetricItem = (index: number) => {
    setActiveMetric(index);
  };

  const onClickBack = useCallback(async () => {
    const questionsCount =
      questionsWithMetric?.[activeMetric].questions?.length ?? 0;

    if (activeQuestion !== 0) {
      setActiveQuestion(activeQuestion - 1);
    } else if (activeMetric !== 0) {
      setActiveMetric(activeMetric - 1);
      setActiveQuestion(questionsCount - 1);
    }
    refetch();
    setSelectedAnswer(undefined);
  }, [questionsWithMetric, activeMetric, activeQuestion, refetch]);

  const isBackButtonDisabled = useMemo(
    () => activeQuestion === 0 && activeMetric === 0,
    [activeMetric, activeQuestion]
  );

  const isContinueButtonDisabled = useMemo(
    () =>
      !(
        selectedAnswer ||
        activeQuestionProps.question?.responses.some((i) => i.isSelected)
      ),
    [activeQuestionProps.question?.responses, selectedAnswer]
  );

  const continueButton: IButton = useMemo(
    () => ({
      label: i18n.t("general.continue"),
      suffixIcon: "ArrowDown",
      variant: "label-w-icon-suffix",
      backgroundColor: ButtonColorVariant.grey,
      onClick: onClickContinue,
    }),
    [onClickContinue]
  );
  const onClickReasonButton = useCallback(
    async (item: NSCandidateReviewerQuestionType.IAnswerOption) => {
      const text = questionsWithMetric?.[activeMetric].questions?.[
        activeQuestion
      ].videoInterviewScore.find(
        (i) => i.is_ai_score === (item.isAISelected ?? false)
      )?.score_description;

      setReviewText(text ?? "");
      setIsDrawerOpen(true);
      setIsAIReview(item.isAISelected);
    },
    [activeMetric, activeQuestion, questionsWithMetric]
  );

  const onClickApplyButton = useCallback(async () => {
    setIsDrawerOpen(false);
    const questionId = idx(
      questionsWithMetric,
      (_) => _[activeMetric].questions[activeQuestion].id
    );

    const selectedAnswerProp =
      selectedAnswer ??
      activeQuestionProps.question?.responses.find((i) => i.isSelected)?.id;

    if (selectedAnswerProp && questionId) {
      updateVideoInterviewDesc({
        videoInterviewQuestionId: questionId,
        scoreDescription: reviewText,
      }).then(() => {
        refetch();
      });
    }
  }, [
    activeMetric,
    activeQuestion,
    activeQuestionProps.question?.responses,
    questionsWithMetric,
    refetch,
    reviewText,
    selectedAnswer,
    updateVideoInterviewDesc,
  ]);

  return {
    barItems,
    selectedAnswer,
    activeMetric,
    questionsWithMetric,
    activeQuestionProps,
    videoUrls,
    candidateInformation,
    continueButton,
    isBackButtonDisabled,
    isDrawerOpen,
    isContinueButtonDisabled,
    reviewText,
    isAIReview,
    closeHandler,
    onClickApplyButton,
    onClickBack,
    onClickReasonButton,
    setReviewText,
    onClickOptionItem,
    setIsDrawerOpen,
    onClickContinue,
    onClickMetricItem,
  };
};

export default useCandidateReviewerQuestionVm;
