import { graphql } from "../gql";

const gqGetInterviewTemplateQuestionsByTemplateId = graphql(/* GraphQL */ `
  query getInterviewTemplateQuestionsByTemplateId(
    $id: Int!
    $question_order: order_by = asc
  ) {
    interview_template_review_metric_question(
      where: { interview_template_id: { _eq: $id } }
      order_by: { question_order: $question_order }
    ) {
      review_metric_question {
        question
        is_shown_to_candidate
        review_metric_question_responses {
          description
          score
          id
        }
        id
        review_metric {
          description
          id
          language
        }
      }
    }
  }
`);

export default gqGetInterviewTemplateQuestionsByTemplateId;
