import { useNavigate, useParams } from "react-router-dom";
import {
  GetProjectEditInfoDocument,
  GetVideoInterviewByProjectIdDocument,
  Language_Enum,
} from "../../../../gql/graphql";
import { useGraphQLQuery } from "../../../../hooks/useGraphQL";
import dayjs from "dayjs";
import i18n from "../../../../library/i18next";

const useInterviewVm = () => {
  const { projectId } = useParams();
  /* const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(false);
  const [shouldSendSMS, setShouldSendSMS] = useState<boolean>(false);
  const [shouldSendEmailBefore3Days, setShouldSendEmailBefore3Days] =
    useState<boolean>(false);
  const [emailContent, setEmailContent] = useState<string>("");
  const [smsContent, setSmsContent] = useState<string>("");
  const [emailContentBefore3Days, setEmailContentBefore3Days] =
    useState<string>("");
  const [drawerContentType, setDrawerContentType] = useState<
    "email" | "sms" | "emailBefore3Days" | null
  >(null);
  const [textAreaValue, setTextAreaValue] = useState<string>(""); */

  const navigate = useNavigate();

  const onClickLanguageEdit = () => {
    navigate(
      `/projects/interview-detail/LANGUAGE_SELECTION?projectId=${projectId}`
    );
  };

  const onClickDateEdit = () => {
    navigate(
      `/projects/interview-detail/DATE_SELECTION?projectId=${projectId}`
    );
  };

  const onClickQuestionsEdit = () => {
    navigate(
      `/projects/interview-detail/REVIEW_METRIC_SELECTION?projectId=${projectId}`
    );
  };

  const onClickSeeInPreview = () => {
    navigate(
      `/projects/interview-detail/SEE_IN_PREVIEW?projectId=${projectId}`
    );
  };

  const { data: projectsData, isSuccess: isSuccessProjectData } =
    useGraphQLQuery(GetProjectEditInfoDocument, undefined, {
      projectId,
    });

  const { data: videoInterviews, isSuccess: isSuccessVideoInterviews } =
    useGraphQLQuery(GetVideoInterviewByProjectIdDocument, undefined, {
      projectId,
    });

  const onClickCreateInterview = () => {
    navigate(`/projects/create-interview?projectId=${projectId}`);
  };

  /* const openDrawer = (contentType: "email" | "sms" | "emailBefore3Days") => {
    setDrawerContentType(contentType);
  };

  const closeDrawer = () => {
    setTextAreaValue("");
    setDrawerContentType(null);
  }; */

  const interviewValidityDate = [
    projectsData?.project_by_pk?.interview_starts_at
      ? dayjs(projectsData.project_by_pk.interview_starts_at)
          .utc()
          .format("DD-MM-YYYY HH:mm")
      : "-",
    projectsData?.project_by_pk?.interview_ends_at
      ? dayjs(projectsData.project_by_pk.interview_ends_at)
          .utc()
          .format("DD-MM-YYYY HH:mm")
      : "-",
  ];

  const interviewLanguage =
    projectsData?.project_by_pk?.language === Language_Enum.Tr
      ? i18n.t("interview.tr")
      : i18n.t("interview.en");

  const isLoading = !isSuccessProjectData || !isSuccessVideoInterviews;

  const isInterviewEditable = videoInterviews?.client_candidate_project.every(
    (project) => project.video_interviews.length === 0
  );

  return {
    isLoading,
    projectsInterviewTemplate:
      projectsData?.project_by_pk?.interview_template?.id,
    /* shouldSendEmail,
    emailContent,
    shouldSendSMS,    
    shouldSendEmailBefore3Days,
     setShouldSendEmailBefore3Days,
    setEmailContentBefore3Days,
    openDrawer,
    setShouldSendSMS,
    setShouldSendEmail,
    drawerContentType,
    textAreaValue,
    setTextAreaValue,
    setSmsContent,
    setEmailContent,
    closeDrawer,
    emailContentBefore3Days,
    smsContent, */
    interviewLanguage,
    interviewValidityDate,
    isInterviewEditable,
    onClickCreateInterview,
    onClickLanguageEdit,
    onClickDateEdit,
    onClickQuestionsEdit,
    onClickSeeInPreview,
  };
};

export default useInterviewVm;
