/* eslint-disable array-callback-return */
import CandidateResultInfoCard from "../../../components/CandidateResultInfoCard/CandidateResultInfoCard";
import Logo from "../../../components/Logo/Logo";
import Modal2 from "../../../components/Modal/Modal2";
import { NSCandidateScoreResultStyle } from "./Candidate-Score-Result.style";

import ScoreChart from "../../../components/ScoreChart/ScoreChart";
import useCandidateScoreResultVm from "./Candidate-Score-ResultVM";
import Loading from "../../../components/Loading/Loading";
import i18n from "../../../library/i18next";
import Icon from "../../../components/Icon/Icon";
import BarChart from "../../../components/BarChart/BarChart";
import SolidButton from "../../../components/SolidButton/SolidButton";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import colors from "../../../style/colors";
import { NSBarChartStyle } from "../../../components/BarChart/BarChart.style";
import LinkButton from "../../../components/LinkButton/LinkButton";
import { NSCandidateListType } from "../../Project/Detail/Candidate-List/Candidate-List.type";


const HTMLContentRenderer = ({ content }: { content: string }) => {
  // Function to convert newlines to <br> tags and wrap paragraphs
  const formatContent = (text:string) => {
    // Replace \n with actual line breaks in HTML
    const withLineBreaks = text.replace(/\\n/g, '<br />');
    
    // Split by double line breaks to create paragraphs
    const paragraphs = withLineBreaks.split('<br /><br />');
    
    // Join paragraphs with proper HTML tags
    return paragraphs
      .map(p => `<p>${p}</p>`)
      .join('');
  };

  return (
    <div 
      className="prose max-w-none text-gray-700 leading-relaxed"
      dangerouslySetInnerHTML={{
        __html: formatContent(content)
      }}
    />
  );
};


function CandidateScoreResult() {
  const candidateScoreResultVM = useCandidateScoreResultVm();

  return candidateScoreResultVM.isLoading ? (
    <Loading />
  ) : (
    <Modal2 onClose={candidateScoreResultVM.closeHandler} isFullWidth>
      <NSCandidateScoreResultStyle.Container id="content">
        <NSCandidateScoreResultStyle.ScoreResultPageContainer id="sub-content">
          <NSCandidateScoreResultStyle.GreenEllipse>
            <Icon name="GreenEllipse" />
          </NSCandidateScoreResultStyle.GreenEllipse>

          <NSCandidateScoreResultStyle.ContentContainer>
            <NSCandidateScoreResultStyle.LogoContainer>
              <Logo type="sideBySide" />
            </NSCandidateScoreResultStyle.LogoContainer>
            <NSCandidateScoreResultStyle.BorderContainer>
              <NSCandidateScoreResultStyle.InnerContainer id="innerContainer">
                <NSCandidateScoreResultStyle.Position>
                  {candidateScoreResultVM.candidateNameSurname}
                </NSCandidateScoreResultStyle.Position>
                <NSCandidateScoreResultStyle.CompanyAndLocation>
                  {candidateScoreResultVM.candidateClientAndProjectName}
                </NSCandidateScoreResultStyle.CompanyAndLocation>
                <NSCandidateScoreResultStyle.WorkingType>
                  {candidateScoreResultVM.interviewTime}
                  <br />
                  {candidateScoreResultVM.reviewerText}
                </NSCandidateScoreResultStyle.WorkingType>
                <NSCandidateScoreResultStyle.ButtonsContainer id="buttonContainer">
                  <NSCandidateScoreResultStyle.LeftButtonContainer>
                    <SolidButton
                      label={i18n.t("candidate.watchVideo")}
                      variant="label-w-icon-prefix"
                      prefixIcon="Video"
                      iconColor={colors.light.light}
                      onClick={candidateScoreResultVM.onClickVideoPreview}
                    />
                    {candidateScoreResultVM.baseType !==
                      NSCandidateListType.ScorePageBaseType.INTERVIEW && (
                      <OutlinedButton
                        label={i18n.t("candidate.rescore")}
                        variant="label-w-icon-prefix"
                        prefixIcon="ArrowRestart"
                        iconColor={colors.primary.primary}
                        onClick={candidateScoreResultVM.onClickRestartButton}
                      />
                    )}
                  </NSCandidateScoreResultStyle.LeftButtonContainer>
                  <LinkButton
                    label={i18n.t("candidate.downloadPDF")}
                    variant="label-w-icon-prefix"
                    prefixIcon="DownloadSecondary"
                    onClick={candidateScoreResultVM.downloadButton}
                  />
                </NSCandidateScoreResultStyle.ButtonsContainer>
                <NSCandidateScoreResultStyle.OverallScoreContainer>
                  <CandidateResultInfoCard
                    header={i18n.t("projects.overallScore")}
                    innerHeader={candidateScoreResultVM.scoreText}
                    innerText={i18n.t("projects.overallScoreInnerText", {
                      key: `${
                        candidateScoreResultVM.baseType ===
                        NSCandidateListType.ScorePageBaseType.INTERVIEW
                          ? candidateScoreResultVM.level
                          : "% " + candidateScoreResultVM.score
                      }`,
                    })}
                    suffixItem={
                      <ScoreChart
                        stringProgressValue={
                          candidateScoreResultVM.baseType ===
                          NSCandidateListType.ScorePageBaseType.INTERVIEW
                            ? candidateScoreResultVM.level
                            : undefined
                        }
                        progress={candidateScoreResultVM.score}
                      />
                    }
                  />
                </NSCandidateScoreResultStyle.OverallScoreContainer>
                <NSCandidateScoreResultStyle.BarChartContainer>
                  <NSCandidateScoreResultStyle.Title>
                    {i18n.t("projects.scoreDetails")}
                    <NSBarChartStyle.Value>100%</NSBarChartStyle.Value>
                  </NSCandidateScoreResultStyle.Title>
                  {candidateScoreResultVM.barChartData?.length > 0 && (
                    <BarChart data={candidateScoreResultVM.barChartData} />
                  )}
                </NSCandidateScoreResultStyle.BarChartContainer>

                {candidateScoreResultVM.explanitionData && (
                  <NSCandidateScoreResultStyle.MetricsAndReviewsContainer>
                    {candidateScoreResultVM.explanitionData.map(
                      (item) =>
                        item.response !== "" && (
                          <NSCandidateScoreResultStyle.MetricsAndReview
                            key={item.label}
                          >
                            <NSCandidateScoreResultStyle.Title>
                              {!item.isUserReview && <Icon name="AI" />}
                              {item.isHaveWeight ? (
                                item.label
                              ) : (
                                <>
                                  <NSCandidateScoreResultStyle.InfoTextBonusQuestion>
                                    {i18n.t("candidate.freeCommentArea")}{" "}
                                  </NSCandidateScoreResultStyle.InfoTextBonusQuestion>
                                  <NSCandidateScoreResultStyle.ExplanationInfo>
                                    <Icon name="WarningCircleInfo" />
                                    {i18n.t("candidate.infoBonusQuestion")}
                                  </NSCandidateScoreResultStyle.ExplanationInfo>
                                </>
                              )}
                            </NSCandidateScoreResultStyle.Title>
                            <NSCandidateScoreResultStyle.SecondaryTitle>
                              {i18n.t("candidate.reason")}
                            </NSCandidateScoreResultStyle.SecondaryTitle>

                            <NSCandidateScoreResultStyle.Explanation>
                            <HTMLContentRenderer content={item.response} />
                            </NSCandidateScoreResultStyle.Explanation>
                          </NSCandidateScoreResultStyle.MetricsAndReview>
                        )
                    )}
                  </NSCandidateScoreResultStyle.MetricsAndReviewsContainer>
                )}
              </NSCandidateScoreResultStyle.InnerContainer>
            </NSCandidateScoreResultStyle.BorderContainer>
          </NSCandidateScoreResultStyle.ContentContainer>

          <NSCandidateScoreResultStyle.Purplellipse>
            <Icon name="PurpleEllipse" />
          </NSCandidateScoreResultStyle.Purplellipse>
        </NSCandidateScoreResultStyle.ScoreResultPageContainer>
      </NSCandidateScoreResultStyle.Container>
    </Modal2>
  );
}

export default CandidateScoreResult;
