import {
  GetProjectEditInfoDocument,
  GetReviewMetricByRoleAndLanguageDocument,
  GetReviewMetricQuestionsByMetricIdDocument,
  Language_Enum,
  UpdateProjectStartAndEndDateDocument,
} from "../../gql/graphql";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { array, date, number, object, string } from "yup";
import i18n from "../../library/i18next";
import { NSCreateInterviewType } from "./Interview-Create.type";
import { useGraphQLMutation, useGraphQLQuery } from "../../hooks/useGraphQL";
import { useLocation, useNavigate } from "react-router-dom";
import { NSDatePickerType } from "../../components/DatePicker/DatePicker.type";
import {
  InterviewPageType,
  useInterviewContext,
} from "../../context/InterviewContext/Interview-Create/InterviewContext";
import { useEffect } from "react";
import { NSStepProgressBarType } from "../../components/StepProgressBar/StepProgressBar.type";
import toast from "react-hot-toast";
import ToastAlert from "../../components/ToastAlert/ToastAlert";

const languageAndDateSchema:
  | NSCreateInterviewType.ILanguageAndDateSelectionFormValues
  | any = object({
  language: array(object({ id: number(), label: string() }))
    .required(i18n.t("forms.selectLanguageError"))
    .default(undefined),
  timePeriod: object({ startDate: date(), endDate: date() }).notRequired(),
}).required();

const useCreateInterviewVm = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const projectId = searchParams.get("projectId");

  const {
    stepProgressBarItems,
    activeStepId,
    activePage,
    metricItems,
    metricQuestionItems,
    isAllMetricQuestionsSelected,
    isNoticeModalOpen,
    warningModalIsOpen,
    setWarningModalIsOpen,
    isValidQuestions,
    selectedLanguages,
    setMetricQuestionItems,
    setMetricItems,
    changePage,
    setSelectedLanguages,
    setStepProgressBarItems,
    setIsValidQuestions,
    onClickModalClose,
    getPreviousMetricsQuestions,
    getNextMetricsQuestions,
  } = useInterviewContext();

  const { data: projectsData } = useGraphQLQuery(
    GetProjectEditInfoDocument,
    { enabled: activePage === InterviewPageType.LANGUAGE_DATE_SELECTION },
    {
      projectId,
    }
  );

  const { data: metricsData, isSuccess: isSuccessMetrics } = useGraphQLQuery(
    GetReviewMetricByRoleAndLanguageDocument,
    {
      enabled:
        !!projectsData?.project_by_pk?.role?.id &&
        (activePage === InterviewPageType.REVIEW_METRIC_SELECTION ||
          activePage === InterviewPageType.LANGUAGE_DATE_SELECTION) &&
        metricItems.length === 0,
    },
    {
      roleId: projectsData?.project_by_pk?.role?.id,
      language: selectedLanguages.map((item) =>
        item.label?.toLowerCase()
      ) as Language_Enum[],
    }
  );

  const {
    data: metricQuestionsData,
    refetch: refetchMetricQuestions,
    isSuccess: isSuccessMetricQuestions,
  } = useGraphQLQuery(
    GetReviewMetricQuestionsByMetricIdDocument,
    {
      enabled: false,
    },
    {
      reviewMetricId: activeStepId,
    }
  );

  useEffect(() => {
    switch (activePage) {
      case InterviewPageType.REVIEW_METRIC_SELECTION:
        if (metricsData && !metricItems.length) {
          if (!metricsData.role_review_metric?.length) {
            toast(
              <ToastAlert
                description={i18n.t("interview.notFoundRoleMetricsError")}
                type="error"
              />,

              {
                id: "notFoundRoleMetricsError",
              }
            );
            navigate(-1);
          }
          const initialMetricItems = metricsData?.role_review_metric.map(
            (item) => ({
              id: item.review_metric.id,
              name: item.review_metric.description,
              isSelected: false,
            })
          );
          setMetricItems(initialMetricItems);
        } else if (!metricsData) {
          toast(
            <ToastAlert
              description={i18n.t("interview.notFoundRoleMetricsError")}
              type="error"
            />,

            {
              id: "notFoundRoleMetricsError",
            }
          );
          navigate(-1);
        }
        break;
      case InterviewPageType.REVIEW_METRIC_QUESTION_SELECTION:
        if (!metricQuestionItems?.[activeStepId]?.length) {
          stepProgressBarItems?.length > 0 && refetchMetricQuestions();
          const initialMetricQuestionItems =
            metricQuestionsData?.review_metric_question.map((item) => ({
              id: item.id,
              name: item.description,
              isSelected: false,
              language:
                item.review_metric.language.toUpperCase() as Language_Enum,
              maxDuration: 5,
              isShownToCandidate: item.is_shown_to_candidate,
            }));

          if (
            initialMetricQuestionItems &&
            !initialMetricQuestionItems.length
          ) {
            toast(
              <ToastAlert
                description={i18n.t("interview.notFoundMetricQuestionsError")}
                type="error"
              />,

              {
                id: "notFoundMetricQuestionsError",
              }
            );
          }

          setMetricQuestionItems((prevItems: any) => ({
            ...prevItems,
            [activeStepId]: initialMetricQuestionItems ?? [],
          }));
        }
      default:
        break;
    }
  }, [metricsData, activeStepId, metricQuestionsData]);

  const updateMetricItems = (items: any) => {
    if (items.length && metricQuestionItems) {
      const selectedMetricIds = metricItems
        .filter((metric) => metric.isSelected)
        .map((metric) => metric.id);

      const filteredData = {};

      selectedMetricIds.forEach((id) => {
        if (metricQuestionItems[id]) {
          // @ts-ignore
          filteredData[id] = metricQuestionItems[id].filter(
            (item: any) => item.isSelected
          );
        }
      });
      setMetricQuestionItems(filteredData);
    }

    setMetricItems(items);
  };

  const updateMetricQuestionItems = (questions: any) => {
    setMetricQuestionItems((prevItems: any) => ({
      ...prevItems,
      [activeStepId]: questions,
    }));
  };

  const projectName = projectsData?.project_by_pk?.project_name;

  const { mutateAsync } = useGraphQLMutation(
    UpdateProjectStartAndEndDateDocument
  );

  const selectedMetricItems = metricItems.filter(
    (item) => item?.isSelected === true
  );
  const isSelectedCompetenciesValid =
    selectedMetricItems.length >= 3 && selectedMetricItems.length <= 5;

  const defaultDates = [
    new Date(),
    dayjs(new Date()).add(15, "day").toDate(),
  ] as NSDatePickerType.Dates;
  const languageOptions = [
    {
      id: 1,
      label: Language_Enum.Tr.toUpperCase(),
    },
    {
      id: 2,
      label: Language_Enum.En.toUpperCase(),
    },
  ];

  const initialLanguages = languageOptions.filter(
    (option) =>
      option.label.toLowerCase() ===
      projectsData?.project_by_pk?.language.toLowerCase()
  );

  const {
    handleSubmit: handleSubmitLanguageAndDate,
    control: controlLanguageAndDate,
  } = useForm<NSCreateInterviewType.ILanguageAndDateSelectionFormValues>({
    resolver: yupResolver(languageAndDateSchema),
    shouldFocusError: false,
    defaultValues: {
      language: initialLanguages,
    },
  });

  const onSubmitLanguageAndDate: SubmitHandler<
    NSCreateInterviewType.ILanguageAndDateSelectionFormValues
  > = (data, event) => {
    event?.preventDefault();
    setSelectedLanguages(data.language);
    const adjustedStartDate = dayjs(data.timePeriod.startDate)
      .add(3, "hour")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    const adjustedEndDate = dayjs(data.timePeriod.endDate)
      .add(3, "hour")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    mutateAsync({
      projectId: projectId,
      interview_ends_at: adjustedEndDate,
      interview_starts_at: adjustedStartDate,
    }).then(() => changePage(InterviewPageType.REVIEW_METRIC_SELECTION));
  };

  const submitHandlerLanguageAndDate = () =>
    handleSubmitLanguageAndDate(onSubmitLanguageAndDate);

  const setStepBarItems = () => {
    const stepProgressBarItems: NSStepProgressBarType.IStepProgressBarItem[] =
      selectedMetricItems.map((item) => ({
        id: item.id,
        label: item.name,
      }));
    setStepProgressBarItems(stepProgressBarItems);
  };

  const onClickInfoIcon = (id: number) => {
    navigate(`/projects/interview/question/${id}`);
  };

  const isLoadingReviewMetricQuestions = !isSuccessMetricQuestions;
  const isLoadingReviewMetrics =
    !isSuccessMetrics || !metricsData?.role_review_metric.length;

  return {
    controlLanguageAndDate,
    projectName,
    languageOptions,
    defaultDates,
    metricItems,
    metricQuestionItems,
    isSelectedCompetenciesValid,
    isLoadingReviewMetricQuestions,
    isLoadingReviewMetrics,
    initialLanguages,
    isAllMetricQuestionsSelected,
    isNoticeModalOpen,
    warningModalIsOpen,
    setWarningModalIsOpen,
    isValidQuestions,
    activeStepId,
    stepProgressBarItems,
    changePage,
    updateMetricItems,
    handleSubmitLanguageAndDate: submitHandlerLanguageAndDate,
    setStepBarItems,
    onClickInfoIcon,
    updateMetricQuestionItems,
    setIsValidQuestions,
    onClickModalClose,
    getPreviousMetricsQuestions,
    getNextMetricsQuestions,
  };
};

export default useCreateInterviewVm;
